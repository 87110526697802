<template>
    <div :class="`m-3 p-2 wrapper wrapper--border wrapper--default`">
        <h3 class="text-center">Inkoop analyse</h3>
        <hr>
        <Loading v-if="loading" />
        <div v-else>
            <strong>* Afgelopen 4 maanden</strong>
            <table :class="`w-100 table--perweek table__border--default`">
                <thead :class="`table__head--default`">
                    <th class="table__head--text">Aantal</th>
                    <th class="table__head--text">Merk</th>
                    <th class="table__head--text">Type</th>
                    <th class="table__head--text">Reg. Jaar</th>
                    <th class="table__head--text">Inkoper</th>
                    <th class="table__head--text">Gem. Inkoopprijs</th>
                    <th class="table__head--text">Aantal verkocht*</th>
                    <th class="table__head--text">Gem. Marge*</th>
                    <th class="table__head--text">Gem. Inkoopprijs*</th>
                    <th class="table__head--text">In S</th>
                    <th class="table__head--text">In P</th>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in purchase_analysis" :key="key" class="`table__row--color`">
                        <td class="table__cell--default">{{ item.aantal }}</td>
                        <td class="table__cell--default">{{ item.merk }}</td>
                        <td class="table__cell--default">{{ item.type }}</td>
                        <td class="table__cell--default">{{ item.regjaar }}</td>
                        <td class="table__cell--default">{{ item.inkoper }}</td>
                        <td class="table__cell--default">{{ item.geminkoopprijs }}</td>
                        <td class="table__cell--default">{{ item.aantal_v_4_maanden }}</td>
                        <td class="table__cell--default">{{ item.gemmarge_4_maanden }}</td>
                        <td class="table__cell--default">{{ item.geminkoopprijs_4_maanden }}</td>
                        <td class="table__cell--default">{{ item.in_s }}</td>
                        <td class="table__cell--default">{{ item.in_p }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";

export default {
    props: ["bu"],
    components: { Loading },
    data: () => ({
        loading: true,
        purchase_analysis: null,
    }),
    created() {
        this.getData(this.bu);
    },
    watch: {
        bu(newbu){
            this.loading = true;
            this.getData(newbu);
        }
    },
    methods: {
        getData(bu) {
            request(`purchase-analysis-per-week/${bu}`, "GET").then(
                ({ purchase_analysis }) => {
                    this.purchase_analysis = purchase_analysis;
                    this.loading = false;
                }
            );
        },
    },
};
</script>